
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Data from "@/data/Ht-Tr-technology/Data.json";

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    Data: any;
  } {
    return {
      Data: Data,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("資料輸入", ["線上來料分析"]);
    });
    return {};
  },
});
